var already_submitted = false

jQuery(document).ready(function() {
    $("#contacto-form").validate({
        ignore: '.ignore',
        focusInvalid: false,
        invalidHandler: function(form, validator) {
            if (!validator.numberOfInvalids())
                return;
            var elem = validator.errorList[0].element.id;

            if ($('#' + elem).is('.select-hidden')) {
                $(window).scrollTo(("#" + elem).parent(), 200, {
                    easing: 'easeOutQuart',
                    offset: -240,
                    queue: false
                });
            } else if ($('#' + elem).is('.g-recaptcha-response')) {
                $(window).scrollTo('.g-recaptcha', 200, {
                    easing: 'easeOutQuart',
                    offset: -240,
                    queue: false
                });
            } else {
                $(window).scrollTo("#" + elem, 200, {
                    easing: 'easeOutQuart',
                    offset: -240,
                    queue: false,
                    onAfter: function() {
                        $("#" + elem).focus();
                    }
                });
            }
        },
        onfocusout: false,
        rules: {
            firstname: {
                required: true,
                minlength: 2
            },
            lastname: {
                required: true,
                minlength: 2
            },
            email: {
                required: true,
                email: true
            },
            areacode: {
                required: true,
                minlength: 2
            },
            phone: {
                required: true,
                minlength: 5
            },
            country: {
                required: true
            },
            province: {
                required: true
            },
            city: {
                required: true,
                minlength: 2
            },
            product: {
                required: true,
                minlength: 2
            },
            'valid-until': {
                required: false
            },
            batch: {
                required: false
            },
            'business_type': {
                required: true
            },
            distributor: {
                required: true,
                minlength: 2
            },
            'client_id': {
                required: true,
                minlength: 2
            },
            message: {
                required: true,
                minlength: 10
            },
            'file-upload-field': {
                required: false
            },
            'g-recaptcha-response': {
                recaptcha: true
            }
        },
        messages: {

        },
        showErrors: function(errorMap, errorList) {
            blnValidated_at_least_once = true;
            $.each(this.successList, function(index, value) {

                var _popover = $(value).popover({
                    trigger: 'manual',
                    placement: 'top',
                    content: value.message,
                    container: '.form-group',
                    template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                });

                $(value).popover('hide');
            });
            var x_count = 0;
            $.each(errorList, function(index, value) {

                if (x_count > 0) {

                } else {
                    x_count++;
                    
                    if (value.element.id == "g-recaptcha-response") {

                        var _popover = $('.g-recaptcha').popover({
                            trigger: 'manual',
                            placement: 'top',
                            content: '',
                            container: 'body',
                            template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                        });

                        $('.g-recaptcha').attr('data-content', value.message);

                        _popover.popover('show');
                        setTimeout(function() {
                            $('.g-recaptcha').popover('hide');
                        }, 2000);

                    } else if ($('#' + value.element.id).is('.select-hidden')) {
                        
                        var _popover = $(value.element).parent().popover({
                            trigger: 'manual',
                            placement: 'top',
                            content: '',
                            container: 'body',
                            template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                        });

                        $(value.element).parent().attr('data-content', value.message);

                        _popover.popover('show');
                        setTimeout(function() {
                            $(value.element).parent().popover('hide');
                        }, 2000);
                        
                    } else {

                        var _popover = $(value.element).popover({
                            trigger: 'manual',
                            placement: 'top',
                            content: '',
                            container: 'body',
                            template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><div class="popover-content"></div></div>'
                        });

                        $(value.element).attr('data-content', value.message);

                        _popover.popover('show');

                        setTimeout(function() {
                            $(value.element).popover('hide');
                        }, 2000);

                    }
                }
            });
        },
        submitHandler: function(form) {
            if (!window.already_submitted) {
                window.already_submitted = true;
                //$(".lltawfegrdewc").val($.cookie('SESS_llsudjwioweew'));
                form.method = "POST";
                form.action = site_url + "/dni-proc";
                form.submit();
            }
        }
    });


    $.validator.addMethod("recaptcha", function(value, element, param) {
        return (grecaptcha.getResponse() != '');
    }, "Debe validar el reCAPTCHA para continuar.");


    $("#btn-submit").click(function(e) {
        e.preventDefault();
        $("#btn-submit").submit();
    });

    if ($("html").hasClass("ie8")) {} else {
        if (!window.addEventListener) {
            window.attachEvent("onorientationchange", doOrientationChange);
        } else {
            window.addEventListener("onorientationchange", doOrientationChange);
        }
        //window.xhandleResize();
    }
});

var xhandleResize = function() {
    $(window).one("resize", function() {
        if (!Modernizr.touch) {
            $('input[type=text],input[type=password],input[type=checkbox],select').each(function() {
                if ($(this).attr("id") == "confirm") {
                    var offset = $(this).offset();
                    var ol = offset.left - 59;
                    var ot = offset.top + 13;
                    $(this).parent('.checkbox').find('.tooltip').css({
                        'left': ol + 'px',
                        'top': ot + 'px'
                    });
                } else {
                    $(this).blur();
                }
            });
        }
        setTimeout("xhandleResize()", 200);
    });
}
var x_reposition = function() {
    $('input[type=text],input[type=password],input[type=checkbox],select,textarea').find(":visible").each(function() {
        $('input[type=text],input[type=password],input[type=checkbox],select,textarea').find(":visible").each(function() {
            if ($(this).attr("id") == "confirm") {
                var offset = $(this).offset();
                var ol = offset.left - 59;
                var ot = offset.top + 13;
                $(this).parent('.checkbox').find('.tooltip').css({
                    'left': ol + 'px',
                    'top': ot + 'px'
                });
            } else {
                $(this).blur();
            }
        });
    });
};

function doOrientationChange() {
    x_reposition();
}
