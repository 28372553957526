window.onload = function onLoad() {
  var progressBar = new ProgressBar.Line('#progress', {
    color: '#007cc3',
    duration: 500,
    easing: 'easeInOut'
  });

  progressBar.animate(1, {
    duration: 500
  }, function() {
    // console.log('Loading finished');
    $('#preloader').fadeOut();
  });
};

$(document).ready(function() {
  /**
   * Input active animation
   */
  $('.animate-label').on('keyup', function() {
    var targetInput = this;
    if ($.trim(this.value).length) {
      $(targetInput).addClass('active');
    } else {
      $(targetInput).removeClass('active');
    }
  });

  /**
   * Safari FIX RELOAD
   */
  $(window).bind('pageshow', function(event) {
    if (event.originalEvent.persisted) {
      window.location.reload()
    }
  });

  $('.gotolink').on('click', function(e) {
    var target = $(this).attr('target');
    if (target = '_blank') {
      e.preventDefault();
      var body = $('body'),
        uri = $(this).attr('href');
      body.animate({'opacity': 0}, 400, function() {
        $(location).attr('href', uri);
      })
    }
  });

  /**
   * Share
   */
  $('.share').on('click', function() {
    $(this).toggleClass('active');
  });

  //  $('.mdb-select').material_select();

  /**
   * Init skrollr
   */
  // skrollr.init({
  //   forceHeight: false,
  //   smoothScrolling: true,
  //   smoothScrollingDuration: 1800
  // });


  // skrollr.init({
  //   mobileCheck: function() {
  //   //hack - forces mobile version to be off
  //   return false;
  //   }
  // });

  /* Hide rotate-device */
  $('input')
    .focus(function() {
      $('.rotate-device').css('left', '100%');
    })
    .blur(function() {
      setTimeout(function() {
        $('.rotate-device').css('left', '0');
      }, 600);
    })

  /**
   * HERO INTERNA IMG/TITLE PARALLAXº
   */
  $(window).on('scroll', function(e) {
    var scrolled = $(window).scrollTop();
    $('.backgroundHome').css('background-position-y', -(scrolled * -0.5) + 'px');
    $('.backgroundHome .box').css('top', -(scrolled * -0.2) + 'px');
    $('.negocios').css('background-position-y', -(scrolled * 0.15) + 'px');
    $('.hero-interna').css('background-position-y', -(scrolled * -0.75) + 'px');
    //$('.hero-interna .box').css('top', -(scrolled * -0.2) + 'px');
  });

  /**
   * IMG DESKTOP/MOBILE
   */
  $(function() {
    if (matchMedia) {
      var mq = window.matchMedia('(min-width: 641px)');
      mq.addListener(WidthChange);
      WidthChange(mq);
    }

    function WidthChange(mq) {
      if (mq.matches) {
        $('.bg_images_dm').each(function() {
          var attr = $(this).attr('data-image-src');
          if (typeof attr !== typeof undefined && attr !== false) {
            $(this).css('background-image', 'url(' + attr + ')');
          }
        });
      } else {
        $('.bg_images_dm').each(function() {
          var attr = $(this).attr('data-image-src-sm');
          if (typeof attr !== typeof undefined && attr !== false) {
            $(this).css('background-image', 'url(' + attr + ')');
          }
        });
      }
    }
  });

  /**
   * SVG TO HTML
   */
  jQuery('img.svg').each(function() {
    var $img = jQuery(this);
    var imgID = $img.attr('id');
    var imgClass = $img.attr('class');
    var imgURL = $img.attr('src');

    jQuery.get(imgURL, function(data) {
      var $svg = jQuery(data).find('svg');
      if (typeof imgID !== 'undefined') {
        $svg = $svg.attr('id', imgID);
      }
      if (typeof imgClass !== 'undefined') {
        $svg = $svg.attr('class', imgClass + ' replaced-svg');
      }
      $svg = $svg.removeAttr('xmlns:a');
      if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
        $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
      }
      $img.replaceWith($svg);
    }, 'xml');
  });

  /**
   * LAZY LOADING
   */
  var load_images = function() {
    $('.lazy').lazy({
      threshold: 0,
      visibleOnly: true,
      beforeLoad: function(element) {
        $(element).addClass('lazy-loading')
      },
      afterLoad: function(element) {
        $(element).removeClass('lazy-loading')
        $(element).addClass('animated fadeIn')
      }
    })
  }
  load_images();

  /* MENU */
  var header = $('.header');
  var navbar = $('.navbar');
  var masckMenu = $('.masck-menu');
  var tl_masckMenu = new TimelineMax({reversed: true});
  var timeline = new TimelineMax({paused: true, reversed: true});

  CSSPlugin.defaultTransformPerspective = 1600;

  timeline.to(masckMenu, 0.5, {autoAlpha: 1, ease: Power1.easeOut})
  timeline.staggerTo($('.menu-primary > li'), .3, {delay: .3, autoAlpha: 1, ease: Power1.easeOut}, 0.1);
  //.to(navbar, 0.5, {autoAlpha: 1, ease: Power1.easeOut}, '-=0.65');

  $('.bt-menu').on('click', function(e) {
    e.preventDefault();
    $('body').toggleClass('menu-open');
    $(navbar).toggleClass('active');
    $('.submenu, .second').removeClass('active');
    // $('.menu-secondary, .submenu .open-slide').removeClass('active');
    timeline.reversed() ? timeline.play() : timeline.reverse();
  });

  $('.submenu .submenu-btn.open-slide').on('click', function(e) {
    e.preventDefault();
    var openSlide = $(this).data('open-slide');
    var nombreSeccion = $(this).text();
    $('.v-mobile').empty().html(nombreSeccion + ' <span>&lt; VOLVER</span>');
    $(this).closest('.submenu').find('.submenu-btn').removeClass('active');
    $(this).addClass('active');
    $('.second').addClass('active');
    $(this).closest('.submenu').find('.second ul').css('display', 'none').removeClass('active');
    $(openSlide).css('display', 'block').addClass('active');
    TweenMax.staggerFromTo($('.col.second ' + openSlide + ' li'), .2, {
      autoAlpha: 0,
      ease: Power1.easeOut
    }, {delay: .15, autoAlpha: 1, ease: Power1.easeOut}, 0.1);
  });

  tl_masckMenu.to($('.masck-menu'), .35, {delay: 0.2, autoAlpha: 1});
  // $('.masck-menu').css({'visibility':'hidden', 'opacity':'0'});

  $('.menu-primary .megamenu')
    .mouseenter(function() {
      tl_masckMenu.play();
    })
    .mouseleave(function() {
      tl_masckMenu.reversed() ? tl_masckMenu.play() : tl_masckMenu.reverse();
    });

  $('.menu-btn').on('click', function(e) {
    e.preventDefault();
    $(this).parent().find(".submenu").addClass("active");
    var nombreSeccion = $(this).text();
    $(".volver").empty().html('' + nombreSeccion + ' <span>< VOLVER</span>');
  });
  $('.masck-menu').on('click', function(e) {
    e.preventDefault();
    $(".bt-menu").trigger("click");
  });

  $('.volver').on('click', function(e) {
    e.preventDefault();
    $(this).parent().removeClass("active");
    $(".second").removeClass("active");
  });

  /**
   * Sticky Menu
   */
  // Hide Header on on scroll down
  var didScroll;
  var lastScrollTop = -100;
  var delta = 5;
  var navbarHeight = $('.header').outerHeight() - 70;

  $(window).scroll(function(event) {
    didScroll = true;
  });

  setInterval(function() {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 10);

  function hasScrolled() {
    var st = $(this).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta)
      return;

    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      $('.header').removeClass('header--sticky');
      $('body').removeClass('scrollUp').addClass('scrollDown');
    } else {
      // Scroll Up
      if (st + $(window).height() < $(document).height()) {
        $('.header').addClass('header--sticky');
        $('body').removeClass('scrollDown').addClass('scrollUp');
      }
    }
    lastScrollTop = st;
  }

  /**
   * Search
   */
  $('#close-btn').click(function() {
    $('#search-overlay').fadeOut();
    $('#search-btn').show();
  });
  $('#search-btn').click(function() {
    $(this).hide();
    $('#search-overlay').fadeIn();
    document.getElementById('search-text').focus();
  });

  $('#search-text').keypress(function(ev) {
    var keycode = (ev.keyCode ? ev.keyCode : ev.which);
    if (keycode == 13) {
      $('#search-form').submit();
    }
  })

  $('#search-form').on('submit', function(e) {
    e.preventDefault();
    var pathArray = window.location.pathname.split('/')
    window.location.href = "/" + pathArray[1] + "/search/" + $('#search-text').val();
  })

  /**
   * Swipper HERO
   */
  var swiperHero = new Swiper('.swiper-hero', {
    pagination: '.swiper-pagination-hero',
    parallax: false,
    paginationClickable: true,
    mousewheelControl: false,
    keyboardControl: true,
    speed: 2500,
    autoplay: 5000,
    resistanceRatio: 0,
    preventClicksPropagation: false,
    preventClicks: false,
    grabCursor: true,
    spaceBetween: 0,
    loop: true,
    effect: 'fade'
  });

  $(".at-consumidor .select-options li").on("click", function(e) {
    e.preventDefault();
    var atCons = $(this).attr('rel');
    TweenMax.to($('.atencion_consumidor ul'), .5, {autoAlpha: 0, display: 'none'});
    TweenMax.to($('.atencion_consumidor ' + atCons + ''), .5, {delay: .5, autoAlpha: 1, display: 'block'});
  });
}); // End
